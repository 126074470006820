import { Section, Tab, Tabs } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet } from '../../../../../shared/http/requests';
import { getOdinSchemaByEntity } from '../../../../../shared/utilities/schemaHelpers';
import BlueprintBadge from '../../../../shared/components/BlueprintBadge';
import MyCasesBottomRowControl from './components/MyCasesBottomRowControl';
import MyCasesContactDrawer from './components/MyCasesContactDrawer';
import MyCasesConversationFeed from './components/MyCasesConversationFeed';
import MyCasesHeader from './components/MyCasesHeader';
import MyCasesList from './components/MyCasesList';
import MyCasesWebSockets from './components/MyCasesWebSockets';
import {
  MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES,
  MY_CASES_SET_ACTIVE_TAB,
  MY_CASES_SET_ASSOCIATED_ACCOUNTS,
  MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT,
  MY_CASES_SET_CASES,
  MY_CASES_SET_CONTACT,
  MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE,
  MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT,
  MY_CASES_SET_SELECTED_ACCOUNT,
  MY_CASES_SET_SELECTED_CASE_CONVERSATION,
  MY_CASES_SET_SELECTED_CASE_EMAILS,
  MY_CASES_SET_SELECTED_CASE_MESSAGES,
  MY_CASES_SET_SELECTED_CASE_NOTES,
} from './store/constants';
import { IMyCasesReducer, myCasesReducer, MyCasesReducerInitialState } from './store/reducer';
import './styles.scss';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, ACCOUNT } = SchemaModuleEntityTypeEnums;
const CASE = 'Case';

interface Props {
  userReducer: any;
}

export type TMyCasesContext = {
  state: IMyCasesReducer;
  dispatch: React.Dispatch<any>;
};

export const MyCasesContext = React.createContext<TMyCasesContext>({
  state: MyCasesReducerInitialState,
  dispatch: () => {},
});

const MyCasesViewV2: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const [state, dispatch] = useReducer(myCasesReducer, MyCasesReducerInitialState);
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);
  const selectedCase = state.selectedCase;

  // On component mount, fetch Case schema and all associated Cases
  useEffect(() => {
    getCaseSchema();
    getCaseList();
  }, []);

  // If there are cases in list preselect the first one
  useEffect(() => {
    if (state.cases.length > 0) {
      // dispatch({ type: MY_CASES_SET_SELECTED_CASE, payload: state.cases[0] });
    }
  }, [state.cases]);

  // If case is selected, fetch activity and associated contact
  useEffect(() => {
    if (selectedCase) {
      getCaseActivity(selectedCase?.id);
      getAssociatedContact(selectedCase?.id);
    }
  }, [state.selectedCase?.id]);

  // If contact is selected, fetch associated Accounts and Cases for that contact
  useEffect(() => {
    if (state.selectedCaseContact) {
      getAssociatedAccounts(state.selectedCaseContact?.id);
      getCasesAssociatedWithContact(state.selectedCaseContact?.id);
    }
  }, [state.selectedCaseContact?.id]);

  // Check if scroll down button should be visible
  useEffect(() => {
    const handleScroll = () => {
      if (state.feedContainerRef?.current) {
        const { scrollTop, scrollHeight, clientHeight } = state.feedContainerRef.current;
        const atBottom = scrollTop + clientHeight >= scrollHeight - 100;
        if (atBottom) {
          dispatch({ type: MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE, payload: false });
        } else {
          dispatch({ type: MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE, payload: true });
        }
      }
    };
    const currentFeedContainer = state.feedContainerRef?.current;
    if (currentFeedContainer) {
      currentFeedContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentFeedContainer) {
        currentFeedContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [state.feedContainerRef]);

  const getCaseSchema = async () => {
    const schema = await getOdinSchemaByEntity('SupportModule', 'Case');
    if (schema) {
      setCaseSchema(schema);
    }
  };

  const getCaseList = async () => {
    httpGet(
      `IdentityModule/v1.0/db-associations/User/${userReducer?.user.id}/one-relation?entity=Case&withLinks=false`,
    ).then((res) => {
      const cases = res?.data['Case']?.dbRecords || [];
      if (cases.length > 0) {
        dispatch({ type: MY_CASES_SET_CASES, payload: cases });
      }
    });
  };

  const getAssociatedContact = async (caseId: string) => {
    try {
      dispatch({ type: MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT, payload: true });
      const res = await httpGet(
        `${SUPPORT_MODULE}/v1.0/db-associations/${CASE}/${caseId}/one-relation?entity=${CONTACT}&withLinks=false`,
      );
      const contact = res?.data[CONTACT]?.dbRecords[0] || undefined;
      dispatch({ type: MY_CASES_SET_CONTACT, payload: contact });
      dispatch({ type: MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT, payload: false });
    } catch (error) {
      dispatch({ type: MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT, payload: false });
      dispatch({ type: MY_CASES_SET_CONTACT, payload: undefined });
    }
  };

  const getAssociatedAccounts = async (contactId: string) => {
    try {
      const res = await httpGet(
        `${SUPPORT_MODULE}/v1.0/db-associations/${CONTACT}/${contactId}/one-relation?entity=${ACCOUNT}&withLinks=false`,
      );
      const accounts = res?.data[ACCOUNT]?.dbRecords || [];
      dispatch({ type: MY_CASES_SET_ASSOCIATED_ACCOUNTS, payload: accounts });

      // Set the first account as selected account
      if (accounts.length > 0) {
        dispatch({ type: MY_CASES_SET_SELECTED_ACCOUNT, payload: accounts[0] });
      }
    } catch (error) {}
  };

  const getCasesAssociatedWithContact = async (contactId: string) => {
    try {
      dispatch({ type: MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES, payload: true });
      const res = await httpGet(
        `${SUPPORT_MODULE}/v1.0/db-associations/${CONTACT}/${contactId}/one-relation?entity=${CASE}&withLinks=false`,
      );
      const cases = res?.data[CASE]?.dbRecords || [];
      dispatch({ type: MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT, payload: cases });
      dispatch({ type: MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES, payload: false });
    } catch (error) {
      dispatch({ type: MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES, payload: false });
    }
  };

  const getCaseActivity = async (caseId: string) => {
    try {
      const caseRes = await httpGet(`ChatModule/v1.0/case/${caseId}/activity`);
      if (caseRes.data) {
        const conversation: DbRecordEntityTransform | undefined =
          caseRes.data?.data?.data?.conversation?.record;
        const messages: DbRecordEntityTransform[] =
          caseRes.data?.data?.data?.conversation?.messages || [];

        const notes: DbRecordEntityTransform[] = caseRes.data?.data?.data?.notes || [];
        const emails = caseRes.data?.data?.data?.emails || [];

        console.log('%cdebug: Selected case activity', 'color:salmon', caseRes.data?.data?.data);

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_CONVERSATION,
          payload: conversation,
        });

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_MESSAGES,
          payload: messages,
        });

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_NOTES,
          payload: notes,
        });

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_EMAILS,
          payload: emails,
        });

        setTimeout(() => {
          const feedContainer = state.feedContainerRef.current;
          if (feedContainer) {
            feedContainer.scrollTop = feedContainer.scrollHeight;
          }
        }, 100);
      }
    } catch (error) {}
  };

  return (
    <MyCasesContext.Provider value={{ state, dispatch }}>
      <MyCasesWebSockets />
      <Row className="myCasesContainer">
        {/* Left Column */}
        <Col xl={6} xxl={5} style={{ height: 'inherit', marginTop: 1 }}>
          <Row align="top">
            {/* Title and Search */}
            <Col span={24} style={{ background: 'white' }}>
              <Section title="Cases" className="titleSection">
                <div style={{ padding: '10px 15px' }}>
                  <Row justify="space-between">
                    <Col span={24}>
                      {/* My Cases Tab */}
                      <Tabs
                        id="myCasesTabs"
                        selectedTabId={state.selectedCasesTabId}
                        onChange={(id: string) => {
                          dispatch({ type: MY_CASES_SET_ACTIVE_TAB, payload: id });
                        }}
                      >
                        <Tab
                          disabled={!state.cases.length}
                          id="my-queue"
                          title={
                            <span style={{ display: 'inline-block' }}>
                              My Queue
                              <BlueprintBadge number={state.cases.length} bold />
                            </span>
                          }
                        />
                        <Tab
                          id="following"
                          disabled
                          title={
                            <span style={{ display: 'inline-block' }}>
                              Following
                              <BlueprintBadge number={0} color="#545a5d" />
                            </span>
                          }
                        />
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </Section>
            </Col>
          </Row>

          {/* My Cases List */}
          <MyCasesList />
        </Col>

        {/* Right Column */}
        <Col xl={18} xxl={19}>
          {/* Case Header */}
          <MyCasesHeader schema={caseSchema!} />
          <div style={{ height: 'calc(100vh - 50px)', background: '#fff' }}>
            <Row>
              {/* Conversation Feed */}
              <MyCasesConversationFeed />

              {/* Contact Details */}
              <MyCasesContactDrawer />
            </Row>

            <Row>
              <Col
                span={24}
                style={{ height: 57, border: '1px solid #d9dada', background: 'white' }}
              >
                <MyCasesBottomRowControl />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </MyCasesContext.Provider>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesViewV2);
