import { Alert, Button, Divider } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../..';
import { updateOrCreateRecordAssociations } from '../../../../../../../../core/recordsAssociations/store/actions';
import { getOdinSchemaByEntity } from '../../../../../../../../shared/utilities/schemaHelpers';
import { toSentenceCase } from '../../../../../../../../shared/utilities/stringHelpers';
import { MY_CASES_SET_CONTACT } from '../../../store/constants';
import CommunicationsHistory from '../../CommunicationsHistory';
import MyCasesAccountSelect from '../MyCasesAccountSelect';

interface Props {
  contact: DbRecordEntityTransform;
  isSearchResult?: boolean;
  isLastSearchResult?: boolean;
  isLoading?: boolean;
  createAssociations: (params: any, cb: any) => void;
}

const { CRM_MODULE, SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { CONTACT } = SchemaModuleEntityTypeEnums;
const CASE = 'Case';

const MyCasesContactDetails: React.FC<Props> = (props: Props) => {
  const { contact, isSearchResult, isLastSearchResult, isLoading, createAssociations } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [isLinkUserAlertVisible, setIsLinkUserAlertVisible] = useState<boolean>(false);
  const [isLinkingUserWithCase, setIsLinkingUserWithCase] = useState<boolean>(false);
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);

  useEffect(() => {
    getCaseSchema();
  }, []);

  const getCaseSchema = async () => {
    const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, CASE);
    if (schema) {
      setCaseSchema(schema);
    }
  };

  const getInitialsFromUserName = (userName: string) => {
    const splitUserName = userName.split(' ');
    const firstName = splitUserName[0]?.toUpperCase() || '-';
    const lastName = splitUserName[1]?.toUpperCase() || '-';
    return firstName[0] + lastName[0];
  };

  const getStatusColor = (status: string | undefined) => {
    switch (status) {
      case 'ACTIVE':
        return '#008949';
      case 'INACTIVE':
        return '#DE6306';
      case 'NEW':
        return '#123EE0';
      default:
        return 'black';
    }
  };

  const accountStatus = getProperty(state.selectedCaseContact_SelectedAccount, 'Status');

  const linkUserWithCase = () => {
    if (caseSchema && contact && state.selectedCase) {
      setIsLinkingUserWithCase(true);

      const schemaAssociation = caseSchema?.associations.find(
        (SA: SchemaAssociationEntity) =>
          SA.childSchema?.entityName === CONTACT || SA.parentSchema?.entityName === CONTACT,
      );

      createAssociations(
        {
          recordId: state.selectedCase?.id,
          schema: caseSchema,
          schemaAssociation: schemaAssociation,
          createUpdate: [
            {
              entity: `${CRM_MODULE}:${CONTACT}`,
              recordId: contact.id,
            },
          ],
        },
        (res: any) => {
          setIsLinkUserAlertVisible(false);
          setIsLinkingUserWithCase(false);
          dispatch({ type: MY_CASES_SET_CONTACT, payload: contact });
        },
      );
    }
  };

  return (
    <div style={{ opacity: isLoading ? 0.3 : 1 }}>
      <Row style={{ marginTop: 5, padding: state.isContactPanelOpen ? 0 : 4 }}>
        <Col
          span={isSearchResult ? 18 : 24}
          style={{ marginTop: 10, textAlign: state.isContactPanelOpen ? 'center' : 'left' }}
        >
          {/* Avatar */}
          <div
            style={{
              display: 'inline-block',
              background: '#EFEFEF',
              padding: 6,
              fontSize: '1em',
              height: 32,
              width: 32,
              borderRadius: 50,
            }}
          >
            <span>{contact?.title ? getInitialsFromUserName(contact.title) : '--'}</span>
          </div>
          {!state.isContactPanelOpen && (
            <span
              style={{
                marginLeft: 8,
                lineHeight: 0,
                fontSize: '1.3em',
                fontWeight: 600,
              }}
            >
              {contact?.title || 'Unknown User'}
            </span>
          )}
        </Col>

        {/* Select Button */}
        {isSearchResult && (
          <Col
            span={6}
            style={{ marginTop: 10, textAlign: state.isContactPanelOpen ? 'center' : 'right' }}
          >
            <Button
              text="Link"
              intent="success"
              disabled={isLoading}
              small
              style={{ borderRadius: 5 }}
              onClick={() => {
                setIsLinkUserAlertVisible(true);
              }}
            />
          </Col>
        )}

        {/* Personal Information + Communications History */}
        {!state.isContactPanelOpen && (
          <>
            <Col span={24} style={{ marginTop: 30 }}>
              <span style={{ fontWeight: 700 }}>Personal Information</span>
            </Col>

            {/* Account Selector, if more than one account is present */}
            {state.selectedCaseContact_Accounts.length > 1 && (
              <Col span={24} style={{ marginTop: 15, marginBottom: 5 }}>
                <MyCasesAccountSelect />
              </Col>
            )}

            {/* Customer Status / WO Step */}
            <Col span={12} style={{ marginTop: 15 }}>
              <span style={{ opacity: 0.5 }}>Customer Status</span>
            </Col>
            <Col span={12} style={{ marginTop: 15 }}>
              <span style={{ opacity: 0.5 }}>Email</span>
            </Col>
            <Col span={12} style={{ marginTop: 5 }}>
              <span
                style={{
                  fontWeight: 600,
                  color: getStatusColor(accountStatus),
                }}
              >
                {toSentenceCase(accountStatus || '') || '-'}
              </span>
            </Col>
            <Col span={12} style={{ marginTop: 5 }}>
              <span>{getProperty(contact, 'EmailAddress') || '-'}</span>
            </Col>

            {/* Phone Number / Email */}
            <Col span={12} style={{ marginTop: 15 }}>
              <span style={{ opacity: 0.5 }}>Phone Number</span>
            </Col>
            <Col span={12} style={{ marginTop: 15 }}>
              {/* <span style={{ opacity: 0.5 }}>Email</span> */}
            </Col>
            <Col span={12} style={{ marginTop: 5 }}>
              <span>{getProperty(contact, 'Phone') || '-'}</span>
            </Col>
            <Col span={12} style={{ marginTop: 5 }}>
              {/* <span>{getProperty(contact, 'EmailAddress') || '-'}</span> */}
            </Col>

            {/* History */}
            {!isSearchResult && (
              <>
                <Col span={24} style={{ marginTop: 30 }}>
                  <span style={{ fontWeight: 700 }}>History</span>
                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                  <CommunicationsHistory />
                </Col>
              </>
            )}

            {/* Bottom Divider for Search Results */}
            {isSearchResult && !isLastSearchResult && (
              <Col span={24} style={{ marginTop: 15 }}>
                <Divider />
              </Col>
            )}
          </>
        )}
      </Row>

      {/* Link User Alert */}
      <Alert
        intent="primary"
        onCancel={() => setIsLinkUserAlertVisible(false)}
        isOpen={isLinkUserAlertVisible}
        cancelButtonText="Cancel"
        onConfirm={linkUserWithCase}
        loading={isLinkingUserWithCase}
      >
        <p>
          Are you sure you want to link {contact.title || 'this user'}{' '}
          {getProperty(contact, 'EmailAddress')
            ? ` (${getProperty(contact, 'EmailAddress')}) `
            : ''}{' '}
          with this case?
        </p>
      </Alert>
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  createAssociations: (params: any, cb: any) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesContactDetails);
