import { Button, Icon } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../..';
import NoteEditor from '../../../../../../../shared/components/NoteFeed/NoteEditor';
import './styles.scss';

interface Props {
  note: DbRecordEntityTransform;
}

const MyCasesNote: React.FC<Props> = (props: Props) => {
  const { note } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const { selectedCase } = state;

  return (
    <Row justify="end" style={{ padding: 15 }}>
      <Col span={24} className="myCasesUsersNote">
        <Row>
          {/* Note Title & Toolbar */}
          <Col span={12}>
            <Icon icon="document" style={{ marginRight: 7 }} />
            <span style={{ fontWeight: 600 }}>Note</span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button small minimal icon="edit" />
            <Button small minimal icon="link" />
          </Col>

          {/* Note Contents */}
          <Col span={24}>
            <NoteEditor
              key={note.id}
              isViewMode={true}
              value={getProperty(note, 'JSONContent')}
              minHeight="auto"
            />
          </Col>

          {/* User / Timestamp */}
          <Col span={24} style={{ marginTop: 12, opacity: 0.6 }}>
            <Row>
              <Col span={12}>
                <span>{note.createdBy?.fullName}</span>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <span>{dayjs(note.createdAt).format('d MMM HH:mm')}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default MyCasesNote;
