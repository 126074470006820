import { Button, Section, TextArea } from '@blueprintjs/core';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  ICreateRecords,
  IGetRecordById,
} from '../../../../../../../core/records/store/actions';
import { getOdinSchemaByEntity } from '../../../../../../../shared/utilities/schemaHelpers';
import { MY_CASES_ADD_NOTE, MY_CASES_TOGGLE_NOTE_EDITOR } from '../../store/constants';
import './styles.scss';

interface Props {
  createRecord: (params: ICreateRecords, cb: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
}

const MyCasesNoteEditorWidget: React.FC<Props> = (props: Props) => {
  const { createRecord, getRecordById } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const selectedCase: DbRecordEntityTransform | undefined = state.selectedCase;

  const [newNoteText, setNewNoteText] = useState<string>('');
  const [isCreatingNote, setIsCreatingNote] = useState<boolean>(false);
  const [noteSchema, setNoteSchema] = useState<SchemaEntity | undefined>(undefined);

  const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
  const { NOTE } = SchemaModuleEntityTypeEnums;

  const smoothScrollFeedToBottom = () => {
    const feedContainer = state.feedContainerRef?.current;
    if (feedContainer) {
      feedContainer.scrollTo({
        top: feedContainer.scrollTop + feedContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    getFileSchema();
  }, []);

  const getFileSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, NOTE);
      if (schema) {
        setNoteSchema(schema);
      }
    } catch (error) {}
  };

  const toggleNoteEditor = () => {
    dispatch({ type: MY_CASES_TOGGLE_NOTE_EDITOR, payload: null });
    setNewNoteText('');
  };

  const createNote = () => {
    if (noteSchema && selectedCase) {
      setIsCreatingNote(true);

      let payload = [{ type: 'paragraph', children: [{ text: newNoteText }] }];

      createRecord(
        {
          schema: noteSchema,
          createUpdate: [
            {
              title: 'Reply to note',
              schemaId: noteSchema.id,
              entity: `${SUPPORT_MODULE}:${NOTE}`,
              properties: {
                JSONContent: payload,
              },
              associations: [
                {
                  entity: selectedCase.entity!,
                  recordId: selectedCase.id,
                  relationType: RelationTypeEnum.CHILD,
                },
                // Attach files to the note
                // ...attachedReplyFileIds.map((fileId: string) => ({
                //   entity: `${SCHEMA_MODULE}:${FILE}`,
                //   recordId: fileId,
                //   relationType: RelationTypeEnum.CHILD,
                // })),
              ],
            },
          ],
        },
        (res: any) => {
          // Fetch note by id and set it to reducer
          getRecordById({ schema: noteSchema, recordId: res.id }, (res: any) => {
            dispatch({ type: MY_CASES_ADD_NOTE, payload: res });
            setIsCreatingNote(false);

            setTimeout(() => {
              smoothScrollFeedToBottom();
            }, 400);
          });
          toggleNoteEditor();
        },
      );
    }
  };

  const isNoteEmpty = () => {
    return newNoteText.trim() === '';
  };

  return (
    <>
      {/* Add Internal Comment Button */}
      {selectedCase && (
        <Button
          text="Add Note"
          icon="plus"
          disabled={state.isAddingNote || state.isComposingEmail || !!state.repliedEmail}
          onClick={toggleNoteEditor}
          outlined
          intent="primary"
          style={{ boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.10)', borderRadius: 5 }}
        />
      )}

      {/* Internal Comment editor */}
      {state.isAddingNote && (
        <div className="newNoteContainer">
          <Section
            compact
            className="newNoteSection"
            title="New Note"
            icon="document"
            rightElement={<Button minimal icon="cross" onClick={toggleNoteEditor} />}
          >
            <div
              style={{
                background: 'white',
                height: 'calc(100vh - 593px)',
                overflowY: 'auto',
                padding: 5,
              }}
            >
              <TextArea
                placeholder="Write a comment"
                fill
                autoFocus
                value={newNoteText}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNewNoteText(e.target.value)
                }
                style={{ height: '100%', boxShadow: 'none' }}
              />
            </div>
            <div
              style={{
                background: 'white',
                borderTop: '1px solid #D9DADA',
              }}
            >
              <Row
                justify="space-between"
                style={{
                  padding: 10,
                  height: 50,
                }}
                align="middle"
              >
                <Col>
                  <Button icon="paperclip" className="newNoteSectionAttachIcon" disabled minimal />
                </Col>
                <Col>
                  <Button
                    intent="danger"
                    outlined
                    text="Delete"
                    disabled={isCreatingNote || isNoteEmpty()}
                    style={{ marginRight: 8, borderRadius: 5 }}
                    onClick={() => setNewNoteText('')}
                  />
                  <Button
                    intent="primary"
                    text="Save"
                    onClick={createNote}
                    style={{ borderRadius: 5 }}
                    disabled={isNoteEmpty()}
                    loading={isCreatingNote}
                  />
                </Col>
              </Row>
            </div>
          </Section>
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesNoteEditorWidget);
