import { Button, Callout, Section, SectionCard, Tag } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Descriptions } from 'antd';
import { FC, useContext, useState } from 'react';
import RecordCard from '../../../../../core/records/components/RecordCard';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import NoteFeed from '../../../../shared/components/NoteFeed';
import DetailView from '../../../../shared/views/DetailView';
import CaseChangeQueueDialog from './CaseChangeQueueDialog';

const { CRM_MODULE, FIELD_SERVICE_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, ADDRESS, WORK_ORDER, ORDER } = SchemaModuleEntityTypeEnums;

interface Props {}

const CaseDetailView: FC<Props> = (props: Props) => {
  const { record, schema } = useContext(DetailViewContext);

  const getCaseIntent = (caseItem: DbRecordEntityTransform) => {
    switch (getProperty(caseItem, 'Impact')) {
      case 'HIGH':
        return 'danger';
      case 'MEDIUM':
        return 'warning';
      case 'LOW':
        return 'success';
      default:
        return 'none';
    }
  };

  const [isChangeQueueDialogOpen, setIsChangeQueueDialogOpen] = useState(false);

  return (
    <>
      <CaseChangeQueueDialog
        record={record}
        openDialog={isChangeQueueDialogOpen}
        onClose={() => setIsChangeQueueDialogOpen(false)}
      />

      <DetailView
        showCollaborators={true}
        showCommunicationHistory={true}
        defaultTabKey="Summary"
        hideNotesTab
        headerExtras={[
          <Button outlined intent="primary" onClick={() => setIsChangeQueueDialogOpen(true)}>
            Change Queue
          </Button>,
        ]}
        customTabs={[{ key: 'Summary', tab: 'Summary' }]}
        customTabBodies={{
          Summary: (
            <>
              <Section title="Incident Details" style={{ marginBottom: 10, overflow: 'auto' }}>
                <Callout intent={'warning'} icon={null}>
                  <Descriptions column={1}>
                    <Descriptions.Item label={'Impact'}>
                      <Tag intent={getCaseIntent(record)}>{getProperty(record, 'Impact')}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Queue">
                      {getProperty(record, 'R_QueueTitle')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Subject">{record.title}</Descriptions.Item>
                    <Descriptions.Item label="Description">
                      {getProperty(record, 'Description')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Assigned To">
                      {getProperty(record, 'R_AssignedUserTitle')}
                    </Descriptions.Item>
                  </Descriptions>
                </Callout>
              </Section>
              <Section title="Notes" style={{ marginBottom: 10, overflow: 'auto' }}>
                <SectionCard>
                  <NoteFeed
                    record={record}
                    includeRelatedEntities={[
                      'Contact',
                      'Address',
                      'Account',
                      ...(['SALES', 'CUSTOMER_EXPERIENCE'].includes(
                        getProperty(record, 'BusinessArea'),
                      )
                        ? ['Order']
                        : []),
                      ...(['INSTALLATION'].includes(getProperty(record, 'BusinessArea'))
                        ? ['WorkOrder']
                        : []),
                    ]}
                  />
                </SectionCard>
              </Section>
            </>
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            borderless
            showItemActionMenu
            shouldPollData={true}
            showWithoutPagination={true}
            pollingIntervalSeconds={5}
            showRecordStage={true}
            record={record!}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={CONTACT}
            visibleProperties={['Phone', 'EmailAddress']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ADDRESS}
            visibleProperties={['Type', 'SalesStatus', 'ExchangeName']}
          />,
          getProperty(record, 'BusinessArea') === 'INSTALLATION' && (
            <RecordCard
              showActionMenu
              borderless
              showItemActionMenu
              shouldPollData={true}
              pollingIntervalSeconds={5}
              showRecordStage={true}
              record={record}
              displayQuickView
              associatedRecordModuleName={FIELD_SERVICE_MODULE}
              associatedRecordEntityName={WORK_ORDER}
              visibleProperties={['Contractor', 'EngineerName']}
            />
          ),
          ['SALES', 'CUSTOMER_EXPERIENCE'].includes(getProperty(record, 'BusinessArea')) && (
            <RecordCard
              showRecordStage
              showActionMenu
              showItemActionMenu
              record={record}
              displayQuickView
              associatedRecordModuleName={ORDER_MODULE}
              associatedRecordEntityName={ORDER}
              visibleProperties={['ActiveDate', 'Source']}
            />
          ),
        ]}
      />
    </>
  );
};

export default CaseDetailView;
