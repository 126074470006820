import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import { MyCasesContext } from '../..';
import { MY_CASES_ADD_MESSAGE } from '../../store/constants';

interface Props {
  userReducer: any;
}

const SOCKET_URL = `${import.meta.env.VITE_ODIN_WEBSOCKET_URL}/ChatModule/TwilioChatWebsocket/v1.0`;

const MyCasesWebSockets: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const { state, dispatch } = useContext(MyCasesContext);
  const [socket, setSocket] = useState<any>(undefined);

  useEffect(() => {
    const s = io(SOCKET_URL, {
      path: '/ws/socket.io',
    });

    if (s) {
      setSocket(s);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () =>
        console.log('%cdebug: MyCasesV2: Connected to WebSockets service', 'color:limegreen'),
      );
      socket.on('message-sent', handleIncomingMessage);
    }

    return () => {
      socket?.off('message-sent', handleIncomingMessage);
    };
  }, [socket, state]);

  const isMessageForThisConversation = (message: DbRecordEntityTransform) => {
    const conversationId = state.selectedCaseConversation?.id;

    if (message && conversationId) {
      return message['Conversation']?.dbRecords?.some(
        (record: DbRecordEntityTransform) => record.id === conversationId,
      );
    }
  };

  const handleIncomingMessage = (message: any) => {
    console.log('%cdebug: Message incoming!', 'color: #bada55', message);
    if (isMessageForThisConversation(message)) {
      const payload = {
        id: message.messageId,
        properties: {
          From: message.properties.From,
          Body: message.properties.Body,
        },
        createdAt: message.createdAt,
        entity: message.entity,
      };
      dispatch({ type: MY_CASES_ADD_MESSAGE, payload });

      // Check if own message by comparing from property with userReducer.user.email
      if (getProperty(message, 'From') === userReducer.user.email) {
        const feedContainer = state.feedContainerRef?.current;
        if (feedContainer) {
          feedContainer.scrollTo({
            top: feedContainer.scrollTop + feedContainer.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
    } else {
      console.log('debug: Message not for this conversation', {
        message,
        conversation: state.selectedCaseConversation,
      });
    }
  };

  return <></>;
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesWebSockets);
