import { Button, MenuItem } from "@blueprintjs/core";
import { ItemRendererProps, Select } from "@blueprintjs/select";
import { SchemaTypeEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity";
import { JSX } from "react";

type SchemaTypesSelectProps = {
  schemaTypes: SchemaTypeEntity[];
  selected: string | null;
  onChange: (selected: string | null) => void;
}

export const SchemaTypesSelect = ({ schemaTypes, selected, onChange }: SchemaTypesSelectProps) => {
  const allOption = { id: 'all', name: 'ALL' } as SchemaTypeEntity;
  const allSchemaTypes = [allOption, ...schemaTypes];
  const selectedItem = allSchemaTypes.find((schemaType) => schemaType.id === selected);
  return (
    <Select<SchemaTypeEntity>
      items={allSchemaTypes}
      activeItem={selectedItem}
      itemRenderer={
        (item: SchemaTypeEntity, { handleClick, handleFocus, modifiers }: ItemRendererProps): JSX.Element | null => (
          <MenuItem
            key={item.id}
            text={item.name}
            roleStructure='listoption'
            active={modifiers.active}
            onClick={handleClick}
            onFocus={handleFocus}    
          />
        )
      }
      onItemSelect={
        function (item: SchemaTypeEntity, event?: React.SyntheticEvent<HTMLElement>): void {
          onChange(item.id);
        }
      }
    >
      <Button
        text={selectedItem ? selectedItem.name : 'Select a Schema Type'}
        rightIcon="caret-down"
        style={{
          minWidth: '250px'
        }}
      />
    </Select>
  )
};
