import { InputGroup, NonIdealState, Spinner } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../..';
import {
  ISearchRecords,
  searchRecordsDebounced,
} from '../../../../../../../../core/records/store/actions';
import { getOdinSchemaByEntity } from '../../../../../../../../shared/utilities/schemaHelpers';
import MyCasesContactDetails from '../MyCasesContactDetails';

interface Props {
  searchRecordsDebounced: (params: any, cb?: any) => void;
}

const { CONTACT } = SchemaModuleEntityTypeEnums;
const { CRM_MODULE } = SchemaModuleTypeEnums;

const MyCasesContactLookup: React.FC<Props> = (props: Props) => {
  const { searchRecordsDebounced } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const { selectedCaseContact } = state;

  const [contactSchema, setContactSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isSearchingContacts, setIsSearchingContacts] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<DbRecordEntityTransform[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    getContactSchema();
  }, []);

  useEffect(() => {
    if (searchQuery.length) {
      searchContacts(searchQuery);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const getContactSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(CRM_MODULE, CONTACT);
      setContactSchema(schema);
    } catch (error) {}
  };

  const searchContacts = async (query: string) => {
    if (contactSchema) {
      setIsSearchingContacts(true);
      searchRecordsDebounced(
        {
          schema: contactSchema,
          searchQuery: {
            terms: searchQuery,
            fields: ['title', 'properties.EmailAddress', 'properties.Phone'],
            schemas: contactSchema.id,
            boolean: undefined,
          },
        },
        (results: any) => {
          setIsSearchingContacts(false);
          const contacts = results.data?.data || [];
          console.log('%cdebug: search results', 'color:salmon', contacts);
          setSearchResults(contacts);
        },
      );
    }
  };

  return (
    <Row style={{ marginTop: 5 }}>
      <Col
        span={24}
        style={{
          marginTop: 10,
          textAlign: state.isContactPanelOpen ? 'center' : 'left',
          marginBottom: 10,
        }}
      >
        <InputGroup
          fill
          placeholder="Search Contacts"
          leftIcon={isSearchingContacts ? null : 'search'}
          value={searchQuery}
          leftElement={
            isSearchingContacts ? <Spinner size={20} style={{ padding: 5 }} /> : undefined
          }
          disabled={!contactSchema || !state.selectedCase}
          onChange={(e: any) => setSearchQuery(e.target.value)}
          style={{ borderRadius: 5 }}
        />
      </Col>

      {/* Search Guide */}
      {!searchQuery.length && !searchQuery.length && (
        <Col span={24} style={{ marginTop: 160, padding: 20 }}>
          <NonIdealState
            icon="search"
            title="Search Contacts"
            description={
              state.selectedCase
                ? 'There is currently no contact linked with this case. Search for contacts by name, email, or phone number.'
                : 'Select a case to search for contacts.'
            }
          />
        </Col>
      )}

      {/* Search Results */}
      {searchResults.length > 0 &&
        searchQuery.length > 0 &&
        searchResults.map((contact: any, i: number) => (
          <MyCasesContactDetails
            contact={contact}
            isSearchResult
            isLoading={isSearchingContacts}
            isLastSearchResult={searchResults.length === i + 1}
          />
        ))}
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecordsDebounced: (params: ISearchRecords, cb: any) =>
    dispatch(searchRecordsDebounced(params, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesContactLookup);
