import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MyCasesContext } from '../..';
import { getBrowserPath } from '../../../../../../../shared/utilities/recordHelpers';
import { MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL } from '../../store/constants';
import MyCasesContactDetails from './MyCasesContactDetails';
import MyCasesContactLookup from './MyCasesContactLookup';
import './styles.scss';

interface Props {}

const MyCasesContactDrawer: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(MyCasesContext);
  const { selectedCaseContact } = state;

  const togglePanel = () => {
    dispatch({ type: MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL, payload: null });
  };

  return (
    <Col span={state.isContactPanelOpen ? 1 : 7} className="myCasesContactDetailsCol">
      <Row style={{ padding: state.isContactPanelOpen ? 7 : 10 }}>
        <Col span={24}>
          <Row justify={state.isContactPanelOpen ? 'center' : 'space-between'}>
            <Col>
              <Button
                icon={state.isContactPanelOpen ? 'drawer-right' : 'drawer-left'}
                text={state.isContactPanelOpen ? '' : 'Hide'}
                minimal
                intent="primary"
                onClick={togglePanel}
              />
            </Col>
            {!state.isContactPanelOpen && state.selectedCaseContact && (
              <Col>
                <Link to={getBrowserPath(selectedCaseContact!)} target="_blank">
                  <Button minimal icon="share" intent="primary" />
                </Link>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24} style={{ height: 'calc(100vh - 230px)', overflowY: 'auto' }}>
          {/* Initial Search for Associated Contact */}
          {state.isSearchingForAssociatedContact && (
            <div style={{ marginTop: 240 }}>
              <NonIdealState icon={<Spinner size={40} />} title="Searching for Contact" />
            </div>
          )}

          {/* Missing Contact */}
          {!selectedCaseContact &&
            !state.isContactPanelOpen &&
            !state.isSearchingForAssociatedContact && <MyCasesContactLookup />}

          {/* Existing Contact */}
          {selectedCaseContact && !state.isSearchingForAssociatedContact && (
            <MyCasesContactDetails contact={state.selectedCaseContact!} />
          )}
        </Col>
      </Row>
    </Col>
  );
};
export default MyCasesContactDrawer;
