import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet, httpPost } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { DualPanelAssignment } from '../../../../../../v2/shared/components/DualPanelAssignment';
import './styles.scss';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  territory: any;
  regions: any[];
  alertMessage: (params: { body: string; type: string }) => void;
  onUpdate: () => void;
}

type TRegion = {
  id: string;
  name: string;
  description: string;
};

const ManageTerritoryRegionsDialog: FC<Props> = (props: Props) => {
  const { territory, alertMessage, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [allRegions, setAllRegions] = useState<TRegion[]>([]);
  const [territoryRegions, setTerritoryRegions] = useState<TRegion[]>([]);
  const [isLoadingRegions, setIsLoadingRegions] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const closeModal = () => {
    setIsDialogOpen(false);
    setAllRegions([]);
    setTerritoryRegions([]);
  };

  const openModal = () => {
    loadAllRegions();
    getTerritoryRegions();
  };

  const getTerritoryRegions = () => {
    if (props.regions) {
      const regions: TRegion[] =
        props.regions.map((t: any) => ({
          id: t.id,
          name: t.name,
          description: t.description,
        })) || [];
      setTerritoryRegions(regions);
    }
  };

  const loadAllRegions = async () => {
    setIsLoadingRegions(true);
    try {
      const res = await httpGet('IdentityModule/v2.0/regions?size=10000');
      const regions: TRegion[] =
        res.data.data?.map((role: any) => ({
          id: role.id,
          name: role.name,
          description: role.description,
        })) || [];

      // Remove regions that the territory already has
      const existingTerritoryRegionIds = props.regions?.map((t: any) => t.id) || [];
      const filteredRegions = regions.filter(
        (t: any) => !existingTerritoryRegionIds.includes(t.id),
      );

      setAllRegions(filteredRegions);
      setIsLoadingRegions(false);
      setIsDialogOpen(true);
    } catch (error: any) {
      setIsLoadingRegions(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not load regions list. ' + message,
        type: 'error',
      });
    }
  };

  const addRegions = async () => {
    const regionIds =
      territoryRegions
        .filter((t) => !props.regions?.map((t) => t.id).includes(t.id))
        ?.map((t: any) => t.id) || [];

    if (!regionIds.length || !territory) return;

    try {
      await httpPost(`IdentityModule/v2.0/territories/${territory.id}/regions`, {
        regionIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not add regions to a territory. ' + message,
        type: 'error',
      });
    }
  };

  const removeRegions = async () => {
    const regionIds =
      props.regions
        ?.filter((t) => !territoryRegions.map((t) => t.id).includes(t.id))
        ?.map((t: any) => t.id) || [];

    if (!regionIds.length || !territory) return;

    try {
      await httpDelete(`IdentityModule/v2.0/territories/${territory.id}/regions`, {
        regionIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not remove regions from a territory. ' + message,
        type: 'error',
      });
    }
  };

  const updateRegionsOnTerritory = async () => {
    setIsSaving(true);

    const addRegionsPromise = addRegions();
    const removeRegionsPromise = removeRegions();

    try {
      await Promise.all([addRegionsPromise, removeRegionsPromise]);
      alertMessage({ body: 'Regions updated successfully', type: 'success' });
      setIsSaving(false);
      closeModal();
      onUpdate();
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update territories in region. ' + message,
        type: 'error',
      });
      setIsSaving(false);
    }
  };

  const isSaveButtonDisabled = () => {
    const existingRegionIds =
      props.regions?.map((t: any) => t.id)?.sort((a: any, b: any) => a.localeCompare(b)) || [];
    const territoryRegionIds = territoryRegions
      .map((t) => t.id)
      ?.sort((a: any, b: any) => a.localeCompare(b));

    return JSON.stringify(existingRegionIds) === JSON.stringify(territoryRegionIds);
  };

  // Add region to territory, remove from the regions list
  const onRegionsPanelChange = (regionId: string) => {
    const region = allRegions.find((t) => t.id === regionId);
    if (region) {
      setTerritoryRegions([...territoryRegions, region]);
      setAllRegions(allRegions.filter((t) => t.id !== regionId));
    }
  };

  // Remove region from territory, add back to the regions list
  const onTerritoryRegionsPanelChange = (regionId: string) => {
    const region: TRegion | undefined = territoryRegions?.find((t) => t.id === regionId);
    if (region) {
      setTerritoryRegions(territoryRegions.filter((t) => t.id !== regionId));
      setAllRegions([...allRegions, region]);
    }
  };

  return (
    <>
      <Button
        small
        minimal
        intent="primary"
        text="Manage"
        loading={isLoadingRegions}
        onClick={openModal}
      />
      <Dialog
        title="Manage Regions in Territory"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ width: '80%' }}
      >
        <DialogBody>
          <DualPanelAssignment
            // Left panel - Available Regions
            leftPanelTitle="Regions"
            leftPanelIcon="globe"
            leftPanelSubtitle="All available regions"
            leftPanelData={allRegions}
            onLeftPanelChange={onRegionsPanelChange}
            leftPanelDisableAdding={allRegions.length === 0}
            // Right Panel - TerritoryRegions
            rightPanelTitle={territory.name}
            rightPanelIcon="area-of-interest"
            rightPanelSubtitle="Regions assigned to this Territory"
            rightPanelData={territoryRegions}
            onRightPanelChange={onTerritoryRegionsPanelChange}
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="Close" text="Close" onClick={closeModal} />,
            <Button
              key="SaveChanges"
              text="Save Changes"
              disabled={isSaveButtonDisabled()}
              intent="primary"
              onClick={updateRegionsOnTerritory}
              loading={isSaving}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ManageTerritoryRegionsDialog);
