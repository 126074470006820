import {
  InputGroup,
  Section,
  SectionCard,
  Switch,
} from '@blueprintjs/core';

export type SettingsFormProps = {
  name: string, setName: (name: string) => void;
  pluralName: string, setPluralName: (pluralName: string) => void;
  apiName: string, setApiName: (apiName: string) => void;
  cascadeDelete: boolean, setCascadeDelete: (cascadeDelete: boolean) => void;
  linkProperties: boolean, setLinkProperties: (linkProperties: boolean) => void;
};
export const SettingsForm = (props: SettingsFormProps) => {
  return (
    <Section title="Link Settings">
      <SectionCard>
        <div className="link-settings">
          <div className="link-settings__item">
            <h4>Name</h4>
            <InputGroup
              type="text"
              fill
              value={props.name}
              onValueChange={props.setName}
            />
          </div>
          <div className="link-settings__item">
            <h4>Plural Name</h4>
            <InputGroup
              type="text"
              fill
              value={props.pluralName}
              onValueChange={props.setPluralName}
            />
          </div>
          <div className="link-settings__item">
            <h4>API Name</h4>
            <InputGroup
              type="text"
              fill
              value={props.apiName}
              onValueChange={props.setApiName}
            />
          </div>
        </div>
      </SectionCard>
      <SectionCard>
        <div className="link-settings">
          <div className="link-settings__item">
            <h4>
              <label htmlFor="cascade-delete-child-recs" >
                Cascade delete child records
              </label>
            </h4>
            <Switch
              id="cascade-delete-child-recs"
              large
              checked={props.cascadeDelete}
              onChange={e => props.setCascadeDelete(e.currentTarget.checked)}
            />
          </div>
          <div className="link-settings__item">
            <h4>
              <label htmlFor="allow-link-attrs">
                Allow link attributes
              </label>
            </h4>
            <Switch
              id="allow-link-attrs"
              large
              checked={props.linkProperties}
              onChange={e => props.setLinkProperties(e.currentTarget.checked)}
            />
          </div>
          <div className='link-settings__item' />
        </div>
      </SectionCard>
    </Section>
  );
};